// ==============================|| THEME CONFIG  ||============================== //

const config = {
  defaultPath: '/survey',
  fontFamily: `'THSarabunNew', sans-serif`,
  i18n: 'en',
  baseUrl: process.env.REACT_APP_KEY_URLAPI

};

export default config;
