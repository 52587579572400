import PropTypes from 'prop-types';

// ==============================|| GUEST GUARD ||============================== //

const GuestGuard = ({ children }) => {
  return children;
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
