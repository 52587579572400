import PropTypes from "prop-types";
import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";

const Header = lazy(() => import("./Headers"));
const Footer = lazy(() => import("./Footer"));

// ==============================|| MINIMAL LAYOUT ||============================== //

const CommonLayout = () => {
  return (
    <>
      <Suspense>
        <Header />
        <Outlet />
        <Footer />
      </Suspense>
    </>
  );
};

CommonLayout.propTypes = {
  layout: PropTypes.string,
};

export default CommonLayout;
