import "./App.css";
import React from "react";
import Locales from "./components/locales";
import Routes from "./routes";
import { createTheme, ThemeProvider } from "@mui/material/styles";

function App() {
  const theme = createTheme({
    typography: {
      fontFamily: ["Poppins", "sans-serif"].join(","),
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <Locales>
        {/* <div className="bg-[url(./assets/images/Background.png)] bg-repeat bg-cover bg-center"> */}
        <Routes />
        {/* </div> */}
      </Locales>
    </ThemeProvider>
  );
}

export default App;
