import { createStore, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import langReducer from './reducers/langReducers';

const rootReducer = combineReducers({
  lang: langReducer
});

const store = createStore(rootReducer);

export default store;