import config from '../../config';

const initialState = {
  locale: config.i18n,
  messages: require(`../../utils/locales/${config.i18n}.json`),
};

const langReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_LANGUAGE":
      return {
        ...state,
        locale: action.payload.locale,
        messages: require(`../../utils/locales/${action.payload.locale}.json`),
      };
    default:
      return state;
  }
};

export default langReducer;
