import { lazy } from "react";
import GuestGuard from "../utils/route-guard/Guest";
import CommonLayout from "../layouts/CommonLayout";

const LandingPage = lazy(() => import("../views/landingpage"));

const GuestRoutes = {
  path: "/",
  element: (
    <GuestGuard>
      <CommonLayout />
    </GuestGuard>
  ),
  children: [
    {
      path: "/",
      element: <LandingPage />,
    },
  ],
};

export default GuestRoutes;
